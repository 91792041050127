import { Link } from '~/components/Link';
import { useUTM } from '~/hooks/useUtm';

import appStoreBadge from '../assets/app-store-badge.svg';
import googlePlayBadge from '../assets/google-play-badge.svg';
import logo from '../assets/new-ramdam-logo.svg';
import qrCode from '../assets/qr-code-ramdam-app.svg';

export default function AuthLandingPage() {
  useUTM();

  return (
    <main className="flex min-h-screen flex-col items-center bg-gray-50 px-4 py-6">
      <header className="mb-6">
        <a href="https://ramd.am">
          <img src={logo} alt="Ramdam" className="h-8" />
        </a>
      </header>
      <article className="flex flex-col-reverse items-center sm:flex-col">
        <section className="w-full rounded-xl border border-light-gray bg-white  p-8 sm:mb-8 sm:w-[600px]">
          <h2 className="text-text-brand-blue mb-1 text-center font-display text-[1.75rem]">For businesses</h2>
          <p className="mb-4 text-center font-body text-l-regular leading-6 text-gray-800">
            Effortlessly order and manage videos
            <br />
            from talented creators
          </p>
          <div className="flex flex-col items-center">
            <Link to="/client/signup" variant="primary" color="ram-yellow" size="xl" className="mb-3 w-[260px]">
              Create a free account
            </Link>
            <Link to="/auth/login" variant="secondary" color="default" size="xl" className="w-[260px]">
              Log in
            </Link>
          </div>
        </section>

        <section className="mb-8 w-full rounded-xl border border-light-gray bg-white p-8 shadow-sm sm:mb-0 sm:w-[600px]">
          <h2 className="text-text-brand-blue mb-1 text-center font-display text-[1.75rem]">For creators</h2>
          <p className="mb-4 text-center font-body text-l-regular leading-6 text-gray-800">
            Download our app and be part of our
            <br />
            creator community
          </p>
          <div className="flex flex-col items-center justify-center sm:flex-row sm:space-x-4">
            <img src={qrCode} alt="QR Code" className="mb-3 h-32 w-32 sm:mb-0" />
            <div className="flex flex-col justify-center space-y-2">
              <Link
                to="https://play.google.com/store/apps/details?id=app.ramd.am&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
                rel="noreferrer"
                variant="tertiary"
              >
                <img src={googlePlayBadge} alt="Get it on Google Play" className="h-12" />
              </Link>
              <Link
                to="https://apps.apple.com/us/app/ramdam-promote-apps-get-paid/id6444769579?itsct=apps_box_badge&itscg=30200"
                target="_blank"
                rel="noreferrer"
                variant="tertiary"
              >
                <img src={appStoreBadge} alt="Download on the App Store" className="h-12" />
              </Link>
            </div>
          </div>
        </section>
      </article>

      <footer className="mt-8 text-center text-sm text-coldgray-500">
        <span className="text-gray-800">© Ramdam</span>
        <span className="mx-2 text-gray-200">•</span>
        <Link
          to="https://www.ramd.am/about"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
          variant="tertiary"
        >
          <span className="text-gray-800">Contact</span>
        </Link>
        <span className="mx-2 text-gray-200">•</span>
        <Link
          to="https://www.ramd.am/privacy-policy"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
          variant="tertiary"
        >
          <span className="text-gray-800">Privacy Policy</span>
        </Link>
        <span className="mx-2 text-gray-200">•</span>
        <Link
          to="https://www.ramd.am/terms-of-services"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
          variant="tertiary"
        >
          <span className="text-gray-800">T&Cs</span>
        </Link>
      </footer>
    </main>
  );
}
